import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container className="py-5">
      <h1> Erreur 404 | Page non trouvée</h1>
      <p>La page demandée n'existe pas !</p>
    </Container>
  </Layout>
)

export default NotFoundPage
